import { Link } from "react-router-dom";
import "./btn.css";

const PrimaryBtn = ({ link, btnName }) => {
  return (
    <>
      <Link to={link} className="pbtn">
        {btnName}
      </Link>
    </>
  );
};

export default PrimaryBtn;
