import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import hairStyles from "./hairStyles";

export default function HairStyles() {
  return (
    <div className="bg-pink-100">
      <div className="mx-auto max-w-7xl px-4 py-5 sm:px-6 sm:py-24 lg:px-8">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 mb-6">
          Book Your Preferred Style
        </h2>

        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4">
          {hairStyles.map((style) => (
            <div key={style.id} className="group relative">
              <div className="overflow-hidden rounded-lg shadow-md group-hover:shadow-xl">
                <Carousel
                  showArrows={true}
                  showThumbs={false}
                  infiniteLoop={true}
                >
                  {style.images.map((image, index) => (
                    <div key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="object-cover object-center h-64 lg:h-80 w-full"
                      />
                    </div>
                  ))}
                </Carousel>
              </div>
              <div className="mt-3">
                <h3 className="text-lg font-semibold text-gray-900">
                  <Link to={style.to} className="hover:text-gray-600">
                    {style.name}
                  </Link>
                </h3>
                <p className="text-sm text-gray-600">{style.addOns}</p>
                <p className="text-sm text-gray-600">{style.des}</p>
                <div className="fl">
                  <p className="text-sm font-medium text-gray-900">
                    {style.price}
                  </p>
                  <Link to={style.to}>
                    <button className="fl-brt">{style.bookBtn}</button>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
