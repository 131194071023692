import bohoBob1 from "./images/boho-bob/boho 1.jpg";
import bohoBob2 from "./images/boho-bob/boho 2.jpg";
import bohoBob3 from "./images/boho-bob/boho 4.jpg";

const hairStyles = [
  {
    id: 1,
    name: "Boho Bob",
    to: "https://Jbcbraiding.as.me/boho-bobo",
    images: [
      {
        src: "https://cdn-s.acuityscheduling.com/appointmentType-thumb-58322467.jpg?1707047183",
        alt: "Boho Bob style 1",
      },
      {
        src: bohoBob1,
        alt: "Boho Bob",
      },
      {
        src: bohoBob2,
        alt: "Boho Bob",
      },
      {
        src: bohoBob3,
        alt: "Boho Bob",
      },
    ],

    addOns: "● Braiding Hair Included",
    price: "$160",
    bookBtn: "Book Now",
  },
  {
    id: 2,
    name: "Bob /shoulder length Box Braid",
    to: "https://Jbcbraiding.as.me/bob-shoulder-length-box-braid",
    images: [
      {
        src: "https://cdn-s.acuityscheduling.com/appointmentType-thumb-59253661.jpeg?1708183583",
        alt: "Boho Bob style 2",
      },
    ],
    addOns: "● Braiding Hair Included",
    price: "$160",
    bookBtn: "Book Now",
  },
  {
    id: 3,
    name: "Fulani boho braids",
    to: "https://Jbcbraiding.as.me/fulani",
    images: [
      {
        src: "https://cdn-s.acuityscheduling.com/appointmentType-thumb-58325724.jpg?1707050111",
        alt: "jbcbraids",
      },
    ],
    addOns: "● Braiding Hair Included",
    price: "$220.00",
    bookBtn: "Book Now",
  },
  {
    id: 4,
    name: "Gypsy braids",
    to: "https://Jbcbraiding.as.me/gypsy",
    images: [
      {
        src: "https://cdn-s.acuityscheduling.com/appointmentType-thumb-60412435.jpeg?1710398446",
        alt: "jbcbraids",
      },
    ],
    addOns: "● Braiding Hair Included",
    price: "$220.00",
    bookBtn: "Book Now",
  },
  {
    id: 5,
    name: "Large bohemian knotless",
    to: "https://Jbcbraiding.as.me/large-boho-knotless",
    images: [
      {
        src: "https://cdn-s.acuityscheduling.com/appointmentType-thumb-63604976.jpeg?1719020315",
        alt: "jbcbraids",
      },
    ],
    addOns: "● Braiding Hair Included",
    price: "$180.00",
    bookBtn: "Book Now",
  },
  {
    id: 6,
    name: "Medium large bohemian knotless",
    to: "https://Jbcbraiding.as.me/med-boh-knotless",
    images: [
      {
        src: "https://cdn-s.acuityscheduling.com/appointmentType-thumb-58839336.jpeg?1719059558",
        alt: "jbcbraids",
      },
    ],
    addOns: "● Braiding Hair Included",
    price: "$200.00",
    bookBtn: "Book Now",
  },
  {
    id: 7,
    name: "Medium/s bohemian knotless",
    to: "https://Jbcbraiding.as.me/?appointmentType=64841892",
    images: [
      {
        src: "https://cdn-s.acuityscheduling.com/appointmentType-thumb-64841892.jpeg?1719542609",
        alt: "jbcbraids",
        bookBtn: "Book Now",
      },
    ],
    addOns: "● Braiding Hair Included",
    price: "$220.00",
    bookBtn: "Book Now",
  },
  {
    id: 8,

    to: "https://www.jannybeautycare.com/services",
    bookBtn: "View All",
    images: [
      {
        src: "https://cdn-s.acuityscheduling.com/appointmentType-thumb-58322394.jpg?1707049539",
        alt: "jbcbraids",
      },
    ],
  },
  // More products...
];

export default hairStyles;
