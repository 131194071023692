import Modal1 from "../Modal/Modal1";
import Iframe from "./Iframe";
import "./Menu.css";

const Services = () => {
  return (
    <div className="service">
      <Modal1 />
      <Iframe />
    </div>
  );
};

export default Services;
