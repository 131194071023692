import "./hero.css";
import InfiniteAnimation from "../infinite/InfiniteAnimation";
import HairStyles from "../HairStyles/HairStyles.jsx";
import Home from "../updates/Home/Home.jsx";

const Hero = () => {
  return (
    <div className="heroBody text-center bg-pink-100 ">
      <Home />

      <InfiniteAnimation />
      <HairStyles />
    </div>
  );
};

export default Hero;
