const Iframe = () => {
  return (
    <div className="">
      <iframe
        src="https://app.acuityscheduling.com/schedule.php?owner=31344618"
        title="Schedule Appointment"
        width="100%"
        height="800"
        frameBorder="0"
      ></iframe>
    </div>
  );
};

export default Iframe;
