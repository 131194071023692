import { useState, useEffect } from "react";
import "./Modal.css";

const Modal1 = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);
  const hideModal = () => {
    setIsVisible(false);
  };

  return (
    <>
      {isVisible && (
        <div className="modal">
          <div className="content">
            <div className="imgside">
              <img
                src="https://img.freepik.com/free-photo/stylist-woman-taking-care-her-client-afro-hair_23-2149259374.jpg?t=st=1726341410~exp=1726345010~hmac=9b9172deff7eaabd4daf9d39c9300a472a0e663c91adc8f90f440b3e4c8c2864&w=740"
                alt="hair blown dry"
              />
            </div>
            <h2>Please Note</h2>
            <p>Endeavor to come with your hair washed and blown out.</p>
            <span>Thanks.</span>
            <button onClick={hideModal}>Continue</button>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal1;
